import LayoutA2 from "../../components/layout-a2"
import React from "react"
import "../themeA1/enroll-info-list.css"
import EnrollRacesList from '../../components/enroll-races-list'


export default class A2EnrollInfoList extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA2/profile/enrollInfo"

    return(
      <LayoutA2 >
        <EnrollRacesList color={color} src={src} />
      </LayoutA2>
    )
  }
}